import styled from 'styled-components';

export const StyledNavBar = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 4rem;
  height: 10rem;
`;

export const StyledTitle = styled.div`
  border-bottom: none;
`;
