import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  font-size: 0.7em;
  line-height: 1.5;
  font-weight: 500;
  color: ${({ theme }) => theme.fadedText};
  margin-bottom: 3rem;
`;
