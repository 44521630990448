import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledMenu = styled.nav`
  display: none;
  flex-direction: column;
  justify-content: center;
  background: #222;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0rem;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  opacity: ${({ open }) => (open ? 1 : 0)};
  visibility: ${({ open }) => (open ? 'visible' : 'hidden')};

  @media (orientation: landscape) {
    padding-top: 10vh;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
  }

  button {
    height: auto;
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #f1f1f1;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;
    box-shadow: none;
    outline: node;
    border: 0;

    &:focus,
    :hover {
      border: 0;
      outline: none;
      background: transparent;
    }
  }

  ${MEDIA.TABLET`
    display: flex;
  `};
`;

export const StyledMenuItem = styled.div`
  text-align: center;
  height: 10vh;
  @media (orientation: landscape) {
    width: 40vw;
    height: 20vh;
  }
`;
