export const accent = '#f90000';

export const darkTheme = {
  name: 'dark',
  body: '#222',
  text: '#F1F1F1',
  linkUnderline: 'rgba(241, 241, 241, 0.2)',
  fadedText: 'rgba(241, 241, 241, 0.65)',
  horizontalRuler: 'rgba(241, 241, 241, .1);',
};

export const lightTheme = {
  name: 'light',
  body: '#F1F1F1',
  text: '#222',
  linkUnderline: 'rgba(34, 34, 34, 0.2)',
  fadedText: 'rgba(34, 34, 34, 0.65)',
  horizontalRuler: 'rgba(34, 34, 34, .1);',
};
