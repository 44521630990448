import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';

const Nav = () => (
  <Container>
    <ul>
      {/* <li>
        <Link to="/projects/">Projects</Link>
      </li> */}
      <li>
        <Link to="/about/">About</Link>
      </li>
      <li>
        <Link href="/contact/">Contact</Link>
      </li>
    </ul>
  </Container>
);

export default Nav;
