import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    transition: background 0.3s ease;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }
  a {
    color: ${({ theme }) => theme.text};
    box-shadow: 0 -.16em 0 ${({ theme }) => theme.linkUnderline} inset; } 
  } 
  a:hover {
    color: ${({ theme }) => theme.text};
    box-shadow: 0 -.16em 0 ${({ theme }) => theme.text} inset; }
  }
  hr {
    border-top: 1px solid ${({ theme }) => theme.horizontalRuler};
  }
`;
