import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { StyledNavBar, StyledTitle } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import { Consumer } from 'store/createContext';
import Burger from 'components/header/burger';
import Menu from 'components/menu';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = () => (
  <AnimatedContainer>
    <StyledNavBar>
      <StyledTitle>
        <Link to="/">
          <Title as="h1" size="large">
            Mario
            <br />
            Altvater
          </Title>
        </Link>
      </StyledTitle>

      <Nav />
      <Consumer>
        {({ open, toggleMenu }) => (
          <Burger open={open} toggleMenu={toggleMenu} />
        )}
      </Consumer>
      <Consumer>
        {({ open, hideMenu }) => <Menu open={open} hideMenu={hideMenu} />}
      </Consumer>
    </StyledNavBar>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
