import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Text = styled.span`
  display: block;
  text-transform: uppercase;
  letter-spacing: 0rem;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '900';
      default:
        return '700';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '3.6rem';
      default:
        return '2rem';
    }
  }};
  line-height: 3.3rem;

  ${MEDIA.TABLET`
    font-size: ${({ size }) => () => {
      switch (size) {
        case 'large':
          return '3.2rem';
        default:
          return '2rem';
      }
    }};
    line-height: 2.8rem;
  `};
`;
