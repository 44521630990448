import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { StyledBurger } from './burger.css';

export default class Burger extends PureComponent {
  render() {
    const { open, toggleMenu } = this.props;

    return (
      <>
        <StyledBurger open={open} onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </StyledBurger>
      </>
    );
  }
}

Burger.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func,
};
