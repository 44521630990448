import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledBurger = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  border: 0;

  &:focus, :hover {
    border: 0;
    outline: none;
  }

  div {
    width: 3rem;
    height: 0.4rem;
    ${props =>
      props.theme.name == 'dark' &&
      `
        background: ${props.open ? props.theme.text : props.theme.text};
      `}
    ${props =>
      props.theme.name !== 'dark' &&
      `
      background: ${props.open ? '#F0F0F0' : '#222'};
      `}
    border-radius: 10px;
    transition: all 0.2s ease;
    position: relative;
    transform-origin: 1px;

    :first-child {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      }
  
      :nth-child(2) {
        opacity: ${({ open }) => (open ? '0' : '1')};
      }
  
      :nth-child(3) {
        transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      }
    }

    &:hover {
      background-color: transparent;
    }
    &:focus {
      background-color: transparent;
    }
  }
  ${MEDIA.TABLET`
    display: flex;
  `};
`;
