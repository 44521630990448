/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { StyledMenu, StyledMenuItem } from './menu.css';

export default class Menu extends PureComponent {
  render() {
    const { open, hideMenu } = this.props;
    function onClick(targetLocation) {
      navigate(targetLocation);
      hideMenu();
    }
    return (
      <StyledMenu open={open}>
        <StyledMenuItem>
          <button
            onClick={function() {
              onClick('/');
            }}
          >
            Introduction
          </button>
        </StyledMenuItem>
        {/* <StyledMenuItem>
          <button
            onClick={function() {
              onClick('/projects');
            }}
          >
            Projects
          </button>
        </StyledMenuItem> */}
        <StyledMenuItem>
          <button
            onClick={function() {
              onClick('/about/');
            }}
          >
            About
          </button>
        </StyledMenuItem>
        <StyledMenuItem>
          <button
            onClick={function() {
              onClick('/contact/');
            }}
          >
            Contact
          </button>
        </StyledMenuItem>
      </StyledMenu>
    );
  }
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func,
};
