import React from 'react';
import { Container } from './footer.css';

const Footer = () => (
  <>
    <hr />
    <Container>
      &copy; 2021 Mario Altvater - Designed with{' '}
      <a href="https://www.sketch.com/" target="_blank" rel="noreferrer">
        Sketch
      </a>
      , built with{' '}
      <a href="https://www.gatsbyjs.com/" target="_blank" rel="noreferrer">
        Gatsby
      </a>
      , hosted on{' '}
      <a href="https://www.netlify.com/" target="_blank" rel="noreferrer">
        Netlify
      </a>
      . Privacy-friendly analytics via{' '}
      <a href="https://plausible.io/" target="_blank" rel="noreferrer">
        Plausible
      </a>
      .
    </Container>
  </>
);

export default Footer;
